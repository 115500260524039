import axios from 'axios';
import globals from '../../globals';
import moment from 'moment';

const state = {
  cmeCredits: [],
  cmeCertificates: [],
};

const getters = {
  cmeCredits: (state) => {
    return state.cmeCredits;
  },
  cmeCertificates: (state) => {
    return state.cmeCertificates;
  },
  cmeActualAndVirtualCertificates: (state) => {
    return state.cmeActualAndVirtualCertificates;
  },
};

const actions = {
  getCmeCreditsOfUser(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(data.url, data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setCmeCredits', response.data.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createCmeCredit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createCmeCreditUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          // If it is a poem credit need to update local store of poems
          if (data.creditable_type === 'App\\Models\\Poem') {
            let poem = context.rootGetters.poems?.find((poem) => poem.id === data.creditable_id);
            if (poem) {
              poem.cme_credits_count = 1;
              poem.cme_credits = [data];
            }
            context.commit('updatePoemInPoems', poem);
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadCmeCredit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadCmeCreditUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: 'CME_Credits_' + data.cme_creditable_id + '.pdf' })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCmeCertificatesOfUser(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(data.url, data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setCmeCertificates', response.data.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createCmeCertificate(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createCmeCertificateUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: 'CME_Certificate.pdf' })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadCmeCertificate(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadCmeCertificateUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: 'CME_Certificate.pdf' })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCmeActualAndVirtualCertificatesByUser(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getCmeActualAndVirtualCertificatesByUserUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          context.commit('setCmeActualAndVirtualCertificates', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadCmeCertificates(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.downloadCmeCertificatesUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: `CME_Certificates_${moment().format('MM-DD-YYYY')}.zip` })
            .then(() => {
              // Update the has_been_exported status for each downloaded certificate
              data.cme_certificates.forEach((certificate) => {
                let certIndex = context.rootGetters.cmeActualAndVirtualCertificates.findIndex((cert) => cert.id === certificate.id);
                if (certIndex !== -1) {
                  context.commit('updateCertificateHasBeenExported', certIndex);
                }
              });
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setCmeCredits(state, data) {
    state.cmeCredits = data;
  },
  setCmeCertificates(state, data) {
    state.cmeCertificates = data;
  },
  setCmeActualAndVirtualCertificates(state, data) {
    state.cmeActualAndVirtualCertificates = data;
  },
  updateCertificateHasBeenExported(state, index) {
    state.cmeActualAndVirtualCertificates[index].cme_credits[0].has_been_exported = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
