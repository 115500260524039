<template>
  <transition name="slide">
    <div v-if="isModuleProgressSidebarOpen" class="fixed right-0 w-64 h-full px-1 py-2 pt-6 pr-1 overflow-y-auto text-left text-white bg-white border-l border-gray-200 top-14" style="z-index: 100">
      <div class="flex flex-row items-center justify-between mt-1 mb-3">
        <h1 class="ml-2 font-bold text-gray-700 text-md">Course Progress</h1>
        <div class="mr-1">
          <Button class="p-button-text" @click="onModuleSidebarToggle" id="toggleModuleProgressSidebar">
            <i class="text-gray-400 pi pi-times navbar-icon" style="font-size: 1rem"></i>
          </Button>
        </div>
      </div>
      <template v-if="isLoading">
        <div class="flex flex-row items-center justify-center h-40">
          <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem; color: #5e76ff"></i>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col">
          <div v-for="(module, index) in selectedCourse.modules" :key="module.id" :module="module" class="flex flex-row items-center justify-start p-4 text-xs border-b border-gray-50" :class="['Unlocked', 'Complete'].includes(module.status) ? 'cursor-pointer hover:bg-gray-100 font-semibold text-gray-900 group' : ' cursor-not-allowed text-gray-600 '" @click="onModuleClick(module)">
            <span class="mr-2">
              <i class="p-1 mx-1 font-bold text-white bg-green-700 rounded-full pi pi-check" style="font-size: 0.75rem" v-if="module.status === 'Complete'" />
            </span>
            <span class="transition duration-500 ease-in-out transform group-hover:translate-x-2"> Module {{ index + 1 }}: {{ module.name?.substring(0, 45) }} </span>
          </div>
        </div>
      </template>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['isModuleProgressSidebarOpen'],
  emits: ['onModuleSidebarToggle'],
  data() {
    return {
      isOpen: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['selectedCourse', 'loggedInUser', 'courseModule']),
  },
  methods: {
    ...mapActions(['getCourse']),
    onModuleClick(module) {
      if (!['Unlocked', 'Complete'].includes(module.status)) {
        return;
      }
      this.$router.push(`/course-topics/${this.selectedCourse.course_topic.id}/courses/${this.selectedCourse.id}/modules/${module.id}`);
    },
    onModuleSidebarToggle() {
      this.$emit('onModuleSidebarToggle');
    },
  },
  watch: {
    $route(to, from) {
      if (['SingleModule'].includes(to.name)) {
        if (window.innerWidth > 1025 && !this.isModuleProgressSidebarOpen) {
          this.onModuleSidebarToggle();
        }
      } else {
        if (this.isModuleProgressSidebarOpen) {
          this.onModuleSidebarToggle();
        }
      }
    },
    isModuleProgressSidebarOpen(newVal) {
      this.isLoading = false;
      if (newVal) {
        let timeOutLength = this.selectedCourse ? 0 : 500;
        if (timeOutLength !== 0) {
          this.isLoading = true;
        }

        setTimeout(() => {
          if (this.courseModule && this.selectedCourse?.id !== this.courseModule?.course?.id) {
            let courseId = this.courseModule ? this.courseModule?.course?.id : this.selectedCourse?.id;
            this.getCourse({ user_id: this.loggedInUser.id, course_id: courseId, course_topic_id: this.$route.params.courseTopicId })
              .then(() => {
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
          } else {
            this.isLoading = false;
          }
        }, timeOutLength);
      }
    },
  },
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
