import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import { globalMixin } from '@/mixins';

import PageNotFound from '../views/public/PageNotFound.vue';
import Login from '../views/public/Login.vue';
import Registration from '../views/public/registration/Registration.vue';
import VerifyEmail from '../views/public/VerifyEmail.vue';
import Lectures from '../views/user/lectures/Lectures.vue';
import SingleLecture from '../views/user/lectures/SingleLecture.vue';
import Poems from '../views/user/poems/Poems.vue';
import ViewPoem from '../views/user/poems/ViewPoem.vue';
import CorticoConsultRequest from '../views/public/ExternalReferralRequest/CorticoRequest.vue';
import UserInviteAcceptLinkHandler from '../views/public/UserInviteAcceptLinkHandler.vue';

const routes = [
  //Routes users can visit without logging in
  {
    name: 'Landing',
    path: '/',
    component: Login,
    meta: { requiresVisitor: true },
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    meta: { requiresVisitor: true },
  },
  {
    name: 'Registration',
    path: '/registration',
    component: Registration,
    meta: { requiresVisitor: true, title: 'Registration' },
  },
  {
    name: 'Lectures',
    path: '/lectures',
    component: Lectures,
    meta: { title: 'Lectures' },
  },

  {
    name: 'SingleLecture',
    path: '/lecture/:lectureId',
    component: SingleLecture,
  },
  {
    name: 'Poems',
    path: '/poems',
    component: Poems,
    meta: { title: 'POEMs' },
  },
  {
    name: 'ViewPoem',
    path: '/poem/:id',
    component: ViewPoem,
    meta: { title: 'POEM' },
  },
  {
    name: 'VerifyEmail',
    path: '/verifyemail/:userId/:code',
    component: VerifyEmail,
    meta: { requiresVisitor: true },
  },
  {
    name: 'CorticoConsultRequest',
    path: '/cortico/consult-request/:requestCode',
    component: CorticoConsultRequest,
    meta: { requiresAuth: false },
  },
  // The following link will no longer be useful for future invites, but any invites already sent before 31st July 2024 needs this handler
  {
    name: 'UserInviteAcceptLinkHandlerOld',
    path: '/join-hallway/:inviteCode',
    component: UserInviteAcceptLinkHandler,
    meta: { requiresAuth: false },
  },
  {
    name: 'UserInviteAcceptLinkHandler',
    path: '/accept-invite/:inviteCode',
    component: UserInviteAcceptLinkHandler,
    meta: { requiresAuth: false },
  },
  // Everything else is lazyloaded/prefetched after initial chunk load
  {
    name: 'DemoLogin',
    path: '/cep',
    component: () => import('../views/public/DemoLogin.vue'),
    meta: { requiresVisitor: true },
  },
  {
    name: 'DemoLoginNovaScotia',
    path: '/cep-ns',
    component: () => import('../views/public/DemoLogin.vue'),
    meta: { requiresVisitor: true },
  },
  {
    name: 'AccountSetupPage',
    path: '/account-setup',
    component: () => import('../views/user/onboarding/AccountSetupPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Logout',
    path: '/logout',
    component: () => import('../views/user/Logout.vue'),
  },
  {
    name: 'RegistrationThankYou',
    path: '/thank-you',
    component: () => import('../views/public/registration/RegistrationThankYou.vue'),
    meta: { requiresVisitor: true },
  },
  {
    name: 'GeneralPrivacyPolicy',
    path: '/privacy-policy',
    component: () => import('../views/public/privacy/GeneralPrivacyPolicy.vue'),
    meta: { requiresVisitor: true },
  },
  {
    // may need updating
    name: 'PlatformPrivacyPolicy',
    path: '/platform-privacy-policy',
    component: () => import('../views/public/privacy/PlatformPrivacyPolicy.vue'),
    meta: { requiresVisitor: true },
  },
  {
    // may need updating
    name: 'TermsOfService',
    path: '/terms-of-service',
    component: () => import('../views/public/privacy/TermsOfService.vue'),
    meta: { requiresVisitor: true },
  },
  {
    name: 'Home',
    path: '/home',
    component: () => import('../views/user/Home.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ReferralHistory',
    path: '/referralhistory',
    component: () => import('../views/user/referral/History.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Billing',
    path: '/billing',
    component: () => import('../views/user/billing/Billing.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ReferralTriage',
    path: '/referraltriage',
    component: () => import('../views/user/referral/ReferralTriage.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'AllNotifications',
    path: '/allnotifications',
    component: () => import('../views/user/my_account/AllNotifications.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Referral',
    path: '/referral',
    component: () => import('../views/user/referral/Show.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ReferralDetail',
    path: '/referral/:id',
    component: () => import('../views/user/referral/Show.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'MakeReferral',
    path: '/makereferral',
    component: () => import('../views/user/gp/MakeReferral.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    name: 'Schedules',
    path: '/schedules',
    component: () => import('../views/user/specialist/schedule/All.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Preferences',
    path: '/preferences',
    component: () => import('../views/user/specialist/Preferences.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ExampleConsultForm',
    path: '/exampleconsultform',
    component: () => import('../views/user/specialist/ExampleConsultForm.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'DemoReferral',
    path: '/demoreferral',
    component: () => import('../views/user/specialist/ExampleConsultForm.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Verification',
    path: '/verification',
    component: () => import('../views/user/onboarding/Onboarding.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'AddSchedule',
    path: '/addschedule',
    component: () => import('../views/user/specialist/schedule/AddSchedule/AddSchedule.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ShowMyAccount',
    path: '/myaccount',
    component: () => import('../views/user/my_account/ShowMyAccount.vue'),
    meta: { requiresAuth: true },
  },
  {
    // maybe remove
    name: 'InviteColleague',
    path: '/inviteuser',
    component: () => import('../views/user/InviteColleague.vue'),
  },
  {
    name: 'PasswordResetRequest',
    path: '/passwordreset',
    component: () => import('../views/public/PasswordResetRequest.vue'),
  },
  {
    name: 'PasswordReset',
    path: '/passwordreset/:userId/:code',
    component: () => import('../views/public/PasswordReset.vue'),
  },
  {
    name: 'EmailNotVerified',
    path: '/emailnotverified',
    component: () => import('../views/public/EmailNotVerified.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'CloseAccount',
    path: '/closeaccount',
    component: () => import('../views/user/my_account/CloseAccount.vue'),
  },
  {
    name: 'ManageUsers',
    path: '/manageusers',
    component: () => import('../views/user/org_unit/ManageUsers.vue'),
  },
  {
    name: 'EditUser',
    path: '/editaccount/:userId',
    component: () => import('../views/user/org_unit/EditUser.vue'),
  },
  {
    name: 'ShareConsults',
    path: '/shareconsults',
    component: () => import('../views/user/referral/ShareReferralsWithOrgUnit.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPaths',
    path: '/smartpaths',
    component: () => import('../views/user/specialist/smartpath/SmartPaths.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathBuilder',
    path: '/builder/:smartPathId',
    component: () => import('../views/user/specialist/smartpath/Builder.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathSurvey',
    path: '/pathway/:smartPathId/:contributorId?/:transactionId?',
    component: () => import('../views/user/gp/make_smartpath/SmartPath.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathTransactions',
    path: '/results/:smartPathId',
    component: () => import('../views/user/specialist/smartpath/SmartPathTransactions.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathTransactionHistory',
    path: '/pathwayhistory',
    component: () => import('../views/user/gp/smartpath/SmartPathTransactionHistory.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ReviewSmartPathTransaction',
    path: '/reviewresult/:transactionId',
    component: () => import('../views/user/specialist/smartpath/ReviewTransaction.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Resources',
    path: '/resources',
    component: () => import('../views/user/AllResponseFormRelatedFiles.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'NotifyUserFromTextMessage',
    path: '/nUfT/:code',
    component: () => import('../views/user/NotifyUserFromTextMessage.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'VerifyEmailUpdate',
    path: '/verify-email-update/:code',
    component: () => import('../views/public/VerifyEmailUpdate.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'RecentCallsHistory',
    path: '/recent-calls-history',
    component: () => import('../views/user/caller/RecentCallsHistory.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ManageCallDisplayNames',
    path: '/call-display-names',
    component: () => import('../views/user/caller/ManageCallDisplayNames.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'AddEncounterNote',
    path: '/add-note',
    component: () => import('../views/user/encounter_note/AddOrEditEncounterNote.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Notes',
    path: '/notes',
    component: () => import('../views/user/encounter_note/Notes.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'EditEncounterNote',
    path: '/note/:id',
    component: () => import('../views/user/encounter_note/AddOrEditEncounterNote.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ConnectProgramPoemsInvite',
    path: '/poems-connect',
    component: () => import('../views/user/connect_program/ConnectProgramInvite.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'AccuroApiOauthRedirect',
    path: '/accuro/get-access-token/:code',
    component: () => import('../components/oauth/AccuroApiOauthRedirect.vue'),
    meta: { requiresAuth: true },
  },

  {
    name: 'ConnectProgramPoemsInvited',
    path: '/poems-invite/:connectCode',
    component: () => import('../views/user/connect_program/ConnectProgramInvited.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'Education',
    path: '/education',
    component: () => import('../views/user/education/Education.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'CourseTopics',
    path: '/course-topics',
    component: () => import('../views/user/education/topic/Topics.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Courses',
    path: '/course-topics/:courseTopicId/courses',
    component: () => import('../views/user/education/course/Courses.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Modules',
    path: '/course-topics/:courseTopicId/courses/:courseId/modules',
    component: () => import('../views/user/education/course/Modules.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'SingleModule',
    path: '/course-topics/:courseTopicId/courses/:courseId/modules/:id',
    component: () => import('../views/user/education/SingleModule.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'CMECertifications',
    path: '/cme-certifications',
    component: () => import('../views/user/education/certificate/Certificates.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'EULA',
    path: '/eula',
    component: () => import('../views/public/EulaPage.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'SwitchToOauth',
    path: '/switchtooauth',
    component: () => import('../views/public/SwitchToOauth.vue'),
    meta: { requiresAuth: false },
  },
  { path: '/:pathMatch(.*)*', component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }; // always scroll to top on route change
  },
});

// We only load public-facing routes in the initial chunk, then after we prefetch all the lazyloaded/async routes
async function preloadAsyncRoutes() {
  for (const route of router.getRoutes()) {
    if (!route.components) continue;
    for (const componentOrImporter of Object.values(route.components)) {
      if (typeof componentOrImporter === 'function') {
        try {
          // prefetch each async route component
          await componentOrImporter();
        } catch (err) {}
      }
    }
  }
}

window.addEventListener('load', preloadAsyncRoutes);

router.beforeEach(async (to, from) => {
  let isAuthenticated = await store.getters.isLoggedIn;
  const isPreboarding = await store.getters.isPreboarding;
  const isOnboarding = await store.getters.isOnboarding;
  const loggedInUser = await store.getters.loggedInUser;
  const onboardingForm = await store.getters.onboardingForm;
  const isEmailActivated = await store.getters.isEmailActivated;
  const isAllowedMakeReferral = await store.getters.isAllowedMakeReferral;
  const isOrgUnitHead = await store.getters.isOrgUnitHead;
  const isBookingHallwayAdmin = globalMixin.methods.isBookingHallwayAdmin(loggedInUser);
  const actingUser = await store.getters.actingUser;
  const isCme = await store.getters.isCme;
  const isActive = await store.getters.isActive;
  const authTokenExpirationDate = await store.getters.authTokenExpirationDate;

  if (authTokenExpirationDate && new Date(authTokenExpirationDate) < new Date()) {
    store.dispatch('logoutWithoutRequest');
    isAuthenticated = false;
  }

  let smartPathRoutes = ['SmartPaths', 'ShowSmartPathTransactions', 'SmartPathTransactionHistory', 'SmartPathTransactions', 'SmartPathSurvey', 'SmartPathBuilder'];
  if (to.meta.requiresAuth && !isAuthenticated) {
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  } else if (to.name != 'EmailNotVerified' && isAuthenticated && !isEmailActivated) {
    return {
      path: '/emailnotverified',
    };
  } else if (isAuthenticated && !isOrgUnitHead(loggedInUser) && actingUser === null) {
    await store
      .dispatch('logout')
      .then(() => {
        router.go();
      })
      .catch(() => {});
  } else if (isAuthenticated && isPreboarding(loggedInUser) && to.name !== 'AccountSetupPage' && to.name !== 'Lectures' && to.name !== 'SingleLecture' && to.name !== 'LecturesCmeCredits' && to.name !== 'PoemsCmeCredits' && to.name !== 'Logout' && to.name !== 'ShowMyAccount' && to.name !== 'CloseAccount' && to.name !== 'Poems' && to.name !== 'ViewPoem' && to.name !== 'ConnectProgramPoemsInvite' && to.name !== 'CourseTopics' && to.name !== 'Courses' && to.name !== 'Modules' && to.name !== 'SingleModule' && to.name !== 'CMECertifications') {
    return {
      path: '/account-setup',
    };
  } else if (isAuthenticated && to.name == 'AccountSetupPage' && !isPreboarding(loggedInUser)) {
    return {
      path: '/home',
    };
  } else if (isAuthenticated && smartPathRoutes.includes(to.name) && (loggedInUser.practice_province.name !== 'Nova Scotia' || loggedInUser.provider_type === 'Pharmacist' || isBookingHallwayAdmin)) {
    return {
      path: '/home',
    };
  } else if (isAuthenticated && to.name == 'Billing' && (isPreboarding(loggedInUser) || isBookingHallwayAdmin || loggedInUser.provider_type == 'Pharmacist' || (isOrgUnitHead(loggedInUser) && !isActive(loggedInUser)))) {
    return {
      path: '/home',
    };
  } else if (onboardingForm) {
    if (isAuthenticated && !onboardingForm.is_eula_agreed && to.name != 'EULA') {
      return {
        path: '/eula',
      };
    } else if (isAuthenticated && isOnboarding(loggedInUser) && !isAllowedMakeReferral && to.name == 'MakeReferral') {
      return {
        path: '/verification',
      };
    } else if (isAuthenticated && onboardingForm.is_eula_agreed && to.name == 'EULA') {
      return {
        path: '/home',
      };
    } else if ((to.name == 'EditUser' || to.name == 'ManageUsers') && !isOrgUnitHead(loggedInUser) && !isBookingHallwayAdmin) {
      return {
        path: '/home',
      };
    } else if (to.meta.requiresVisitor && isAuthenticated) {
      if (to.path === '/login' && to.query.redirect) {
        return { path: to.query.redirect };
      }
      if (isPreboarding(loggedInUser)) {
        return {
          path: '/account-setup',
        };
      }
      return { path: '/home' };
    } else if (globalMixin.methods.isNativePlatform() && ['AddEncounterNote', 'Notes', 'EditEncounterNote'].includes(from.name) && !['AddEncounterNote', 'Notes', 'EditEncounterNote'].includes(to.name)) {
      return {
        path: from.path,
      };
    }
    // else if (globalMixin.methods.isNativePlatform() && !isCme(loggedInUser) && ['Lectures', 'SingleLecture', 'CmeCredits'].includes(from.name) && !['Lectures', 'SingleLecture', 'CmeCredits'].includes(to.name)) {
    //   return {
    //     path: from.path,
    //   };
    // }
    else if (globalMixin.methods.isNativePlatform() && ['RecentCallsHistory'].includes(from.name) && !['RecentCallsHistory'].includes(to.name)) {
      return {
        path: from.path,
      };
    }
    // else if (globalMixin.methods.isNativePlatform() && isCme(loggedInUser) && !['Lectures', 'SingleLecture', 'CmeCredits', 'AccountSetupPage', 'ShowMyAccount', 'Logout'].includes(to.name)) {
    //   return {
    //     path: from.path,
    //   };
    // }
  }
});

router.afterEach((to) => {
  // We set the title and meta description with the <Head> component in SingleLecture.vue
  if (to.name != 'SingleLecture') {
    if (to.meta.title) {
      document.title = `${to.meta.title} - Virtual Hallway`;
    } else {
      document.title = `Virtual Hallway`;
    }
  }
});

export default router;
